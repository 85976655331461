import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImage   from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Carousel      from '@interness/web-core/src/components/media/Carousel/Carousel';
import MoreExpander
                     from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';

const imgDescr = ['Meisterbrief', 'Ehemaliges Geschäft in der Issumerstr., Geldern', 'Geschäft Am Steintor 11, Goch'];

function AboutPage(props) {
  return (
    <>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Juwelier Cladder'}>Über uns</Heading>
          <Spacer/>
          <Carousel>
            {props.data.galleryImages.edges.map((image, index) => (
              <>
                <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
                <p>{imgDescr[index]}</p>
              </>
            ))}
          </Carousel>
        </section>
        <Spacer/>
        <section>
          <h2>Tradition und Leidenschaft</h2>
          <p>Juwelier Cladder wurde 1866 durch den Uhrmachermeister Carl Cladder in Geldern gegründet.</p>
          <MoreExpander>
            <p>Seit 2002 befindet sich das Geschäft in Goch, Am Steintor 11, in Sichtweite des Gocher Steintores.
              Als erste Uhrmacherwerkstatt im Kreis Kleve wurde Juwelier Cladder mit dem Zertifikat des Zentralverbandes
              für Uhren und Schmuck in Deutschland ausgezeichnet.</p>
            <p>Die Zertifizierung sichert einen hohen Standard bei der Reparatur von Armbanduhren, sowie die direkte
              Ersatzteilversorgung namhafter Uhrenhersteller.
              Karl-Heinz Cladder begann seine Ausbildung zum Uhrmacher in der Berufsfachschule für Uhrmacher in
              Furtwangen (Schwarzwald). Neben einer umfangreichen feinmechanischen Ausbildung war das Anfertigen von
              Ersatzteilen für Groß- und Kleinuhren ein Schwerpunkt.
              Mit 19 Jahren konnte er die Gesellenprüfung als Jahrgangsbester abschließen. 1988 wurde ihm der
              Meisterbrief mit Auszeichnung der Handwerkskammer Hildesheim überreicht.</p>
            <p>An der Handwerkskammer Düsseldorf folgte 1994 die Meisterprüfung im Goldschmiedehandwerk. 2001 eröffnete
              Karl-Heinz Cladder mit seiner Ehefrau Sabine das Juweliergeschäft Am Steintor 11 in Goch.
              Hier finden Sie auch unsere eigene Uhrmacher- und Goldschmiedewerkstatt.</p>
          </MoreExpander>
        </section>
        <Spacer/>
        <section style={{ textAlign: 'center' }}>
          <CallToAction/>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default AboutPage;

export const query = graphql`
    query AboutPageQuery {
        headerImage: file(relativePath: {eq: "headers/about.jpg"}) {
            name
            childImageSharp {
                fluid(maxWidth: 1980, maxHeight: 660, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        galleryImages: allFile(filter: {relativeDirectory: {eq: "about"}}, sort: {fields: name}) {
            edges {
                node {
                    ...GalleryImages
                }
            }
        }
    }
`;